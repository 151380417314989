import React from "react"
import SEO from "../../components/CommonComponents/SEO"
import Layout from "../../components/CommonComponents/Layout"
import Chernomorsk from "../../components/SingleCase/Chernomorsk"
import ContactBtnBlock from "../../components/SingleCase/ContactBtnBlock"
import Navigate from "../../components/SingleCase/Navigate"
import RelatedProjects from "../../components/SingleCase/RelatedProjects"
import dataForChernomorsk from "../../information/Cases/dataForChernomorsk"
import { mainUrl } from "../../js/config"
import "../../components/SingleCase/PortfolioComponent.scss"

export default class ChernomorskPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showForm: false,
    }
  }

  toggleForm = () => {
    this.setState({ showForm: !this.state.showForm })
  }

  render() {
    const {
      classComponent,
      nextLink,
      prevLink,
      relatedProjects,
      data,
    } = dataForChernomorsk

    return (
      <section className="singleProject Chernomorsk">
        <SEO
          title="Chernomorsk Information Portal Development Case — OS-System"
          description='Development Case of City Information Portal - Chernomosk -  How to combine news summary, property rental and informing about city events at one place.'
          canonical={`${mainUrl}/cases/chernomorsk/`}
        />
        <Layout showFormPoint={300}>
          <Chernomorsk data={data} />
          <Navigate
            classComponent={classComponent}
            nextLink={nextLink}
            prevLink={prevLink}
          />
          <ContactBtnBlock />
          <RelatedProjects relatedProjects={relatedProjects} />
        </Layout>
      </section>
    )
  }
}
