import {
  Section1Bg,
  Section1Img,
  Section3Bg,
  Section3Img,
  Section5Bg,
  Section5Img,
} from "../../images/SingleCase/Chernomorsk"

const dataForChernomorsk = {
  type: "project",
  smart: true,
  classComponent: "Chernomorsk",
  nextLink: "cases/videoconftool/",
  prevLink: "cases/gud-job/",
  data: {
    title: "Information portal",
    subtitle:
      "News, rental and events platform for local population and tourists which unites for a comfortable life and productive business.",
    overview: {
      title: "Project overview",
      about: {
        title: "About client",
        content: "UAE company engaged in Influencer business, 1-10 employees",
      },
      challenge: {
        title: "Business challenge",
        content:
          "Finding a balance between creating a platform that spans three large topics — news, property rental and events — and reworking existing website into a scalable solution to support future growth.",
      },
      specific: [
        {
          type: "Platform",
          content: ["Web"],
          icon: "fa-th-large",
        },
        {
          type: "Technology",
          content: ["React.js", "PHP Symfony", "MySQL"],
          icon: "fa-cog",
        },
        {
          type: "Team",
          icon: "fa-users",
          content: [
            {
              type: "Project Manager",
              count: 1,
            },
            {
              type: "Developers",
              count: 3,
            },
            {
              type: "QA Engineer",
              count: 1,
            },
            {
              type: "Designer",
              count: 1,
            },
          ],
        },
        {
          type: "Duration",
          icon: "fa-calendar",
          content: [
            {
              type: "12 months",
              count: "",
            },
          ],
        },
        {
          type: "Client",
          content: ["Own Product"],
          icon: "fa-user",
        },
      ],
    },
    solution: {
      title: "Solution",
      textFirst: [
        "A web platform that encourages users to generate and post their own content for review and further publishing. Admin backend powered by Symfony allows huge flexibility and control over website’s content and ensures that each journalist and employee has access only to tools he needs. We’re planning to further expand the platform, but want to ensure UX remains smooth and slick - so that we’re not adding features just for the sake of it.",
      ],
    },
    delivered: {
      title: "Value delivered",
      list: [
        "We crafted the initial version of the website to deliver core value in each section - news, rental and events with minimum distractions.",
      ],
    },
    images: {
      bgTop: Section1Bg,
      mockup1: Section1Img,
      bgImage3: Section3Bg,
      mockup3: Section3Img,
      bgImage5: Section5Bg,
      mockup5: Section5Img,
    },
  },
  imageSrc: [],
  relatedProjects: [
    {
      link: "/cases/service-doc",
      id: "service-doc",
      title: "Service Doc",
    },
    {
      link: "/cases/crm-sap-integration",
      id: "crmSap",
      title: "CRM/SAP Integration",
    },
    {
      link: "/cases/gud-job",
      id: "gud-job",
      title: "Gud Job",
    },
    {
      link: "/cases/ilich-realty",
      id: "illich3",
      title: "Ilich Realty",
    },
  ],
  link: "/cases/chernomorsk",
}

export default dataForChernomorsk
