import React from "react"

import OverviewComponent from "../OverviewComponent"

export default function Chernomorsk({ data }) {
  let i = 0
  return [
    <section
      className="first-section white-bg bgImage"
      style={{ backgroundImage: `url(${data.images.bgTop})` }}
      key={i++}
    >
      <div className="bgBlock  chernomorsk">
        <div className="content" style={{ height: "100%" }}>
          <div className="titleBlock">
            <h1>{data.title}</h1>
            <span id="chernomorsk-subheader">{data.subtitle}</span>
          </div>
          <img src={data.images.mockup1} alt="chernomorsk picture" />
        </div>
      </div>
    </section>,

    <OverviewComponent
      {...data}
      classComponent="chernomorsk white-bg"
      key={i++}
    />,

    <section className="pre-solution chernomorsk white-bg" key={i++}>
      <div className="pre-solution-bg">
        <img src={data.images.mockup3} alt="chernomorsk picture" />
      </div>
    </section>,

    <section className="white-bg solution paddings chernomorsk" key={i++}>
      <div className="container">
        <div className="row">
          <div className="col-md-9">
            <h2>{data.solution.title}</h2>
            {data.solution.textFirst.map((item, i) => (
              <p key={i}>{item}</p>
            ))}
          </div>
        </div>
      </div>
    </section>,

    <section className="white-bg post-solution chernomorsk last" key={i++}>
      <div
        className="post-solution-bg"
        style={{ backgroundImage: `url(${data.images.bgImage5})` }}
      ></div>
      <img src={data.images.mockup5} alt="chernomorsk picture" />
    </section>,

    <section className="white-bg value paddings last" key={i++}>
      <div className="container">
        <div className="row">
          <div className="col-md-9">
            <h2>{data.delivered.title}</h2>
            {data.delivered.list.map((item, i) => (
              <p key={i}>{item}</p>
            ))}
          </div>
        </div>
      </div>
    </section>,
  ]
}
